import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  apiToken: "",
  accessToken: "",
  refreshToken: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      console.log({ action });
      state.email = action.payload.email;
      state.apiToken = action.payload.apiToken;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state) => {
      state.email = "";
      state.apiToken = "";
      state.accessToken = "";
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
