import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { accessToken } = useSelector((state) => state.user);

  return <Route {...rest} render={(props) => (accessToken ? <Component {...props} /> : <Redirect to="/login" />)} />;
};
export const LoginRoute = ({ component: Component, ...rest }) => {
  const { accessToken } = useSelector((state) => state.user);

  return <Route {...rest} render={(props) => (accessToken ? <Redirect to="/site-data" /> : <Component {...props} />)} />;
};
