import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Subscribe from './Subscribe'
const Contactus = () => {
  return (
    <div className='backgroundbody'>
        
    <Header />
    <div id="titlebar" className="gradient">
    
          
    <nav aria-label="breadcrumb" className="breadcrumb bg-dark">
      <div className="container d-flex justify-content-between align-items-center py-4">
      <h2 className="text-light">Contact Us</h2>
      <ol className="d-flex list-unstyled">
        <li className="breadcrumb-item"><a href="/" className="text-light text-decoration-none">Home</a></li>
        <li className="breadcrumb-item active text-light" aria-current="page">Contact Us</li>
      </ol>
    </div>
    </nav>
 
</div>

{/* <!-- Content -->  */}
<div className="container">
<div className="row">
<div className="col-12">
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7003.603084083059!2d77.36559849999999!3d28.6357095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce552e5045ad9%3A0x12bbfa73680eee92!2sVaibhav%20Khand%2C%20Indirapuram%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201014!5e0!3m2!1sen!2sin!4v1666794318529!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
</div>
  <div className="col-xl-4 col-lg-4 mt-5">
      <div className="utf-boxed-list-headline-item margin-bottom-35 bg-dark py-3 px-2">
      <h3 className="text-light"><i className='bx bx-map'></i> Office Address</h3>
  </div>
    <div className="mt-4">
  <div className="card shadow p-3 mb-5 bg-light rounded">
    <div className="card-body">
      <ul className="list-unstyled">
        <li className="border-top border-bottom py-2"><strong>Phone:-</strong> (+91) 967-562-2255</li>
        <li className="py-2"><strong>Website:-</strong> <a href="#">https://info.stocometry.com/</a></li>
        <li className="border-top border-bottom py-2"><strong>E-Mail:-</strong> <a href="#">contact@stocometry.com</a></li>              
        {/* <li className="border-bottom py-2"><strong>Address:-</strong> Vaibhav Khand, Indirapuram, Ghaziabad, Uttar Pradesh Pin Code: 201014</li> */}
      </ul>
    </div>
  </div>
       
  </div>		
</div>
<div className="col-xl-8 col-lg-8 mt-5">
  <section id="contact" className="margin-bottom-50">
    <div className="utf-boxed-list-headline-item margin-bottom-35 bg-dark py-3 px-2">
      <h3 className="text-light"><i className='bx bx-file'></i> Contact Form</h3>
    </div>
    <div className="mt-4">
        <form method="post" name="contactform" id="contactform">
    <div className="card shadow p-3 mb-5 bg-light rounded">
      <div className="card-body">
        <div className="row ">
          <div className="col-md-6 ">
          <div className="utf-no-border">
            <input className="border-0 w-100  shadow p-3 mb-2 bg-light rounded" name="name" type="text" id="firstname" placeholder="Frist Name" required />
          </div>
          </div>
          <div className="col-md-6 ">
          <div className="utf-no-border">
            <input className="border-0 bg-none w-100  shadow p-3 mb-2 bg-light rounded" name="name" type="text" id="lastname" placeholder="Last Name" required />
          </div>
          </div>
          <div className="col-md-6 mt-2">
          
            <input className="w-100 border-0  shadow p-3 mb-2 bg-light rounded" name="email" type="email" id="email" placeholder="Email Address" required />
         
          </div>
          <div className="col-md-6 mt-2">
          <div className="utf-no-border ">
            <input className="border-0 w-100  shadow p-3 mb-2 bg-light rounded" name="subject" type="text" id="subject" placeholder="Subject" required />
          </div>
          </div>
        </div>
        <div className="mt-2 ">
          <textarea className="border-0 w-100 shadow p-3 mb-2 bg-light rounded" name="comments" cols="40" rows="3" id="comments" placeholder="Message..." required></textarea>
        </div>
        
          <div className="text-center ">
              <input type="submit" className=" px-2 text-light subscribe" id="submit" value="Submit Message" />
          </div>
      </div>
    </div>
   
          
          
        </form>
    </div>
  </section>
</div>
</div>
<Subscribe />
</div>

    
    <Footer />
    </div>
  )
}

export default Contactus