
const AdvertisementSection = () => {
    const array = ["mutual", "software", "education", "online"]
    const loadResults = (val) =>{
         window.location.href = `/SearchResult?q=${val}#gsc.tab=0&gsc.q=${val}&gsc.page=1`
    }
    return (
        <div className="w-100" style={{ marginTop: 15, marginBottom: 15 }}>
            {array.map((obj, index) => (
                <div key={index} onClick={()=>loadResults(obj)} className=" w-100 advertisement-div px-15 py-10 border" style={{ border: "solid", borderColor: "black", padding: 15, marginBottom: 15, paddingTop: 20, borderRadius: 10 }}>
                    <div className=" w-100 bg-primary py-10 px-10" style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", borderRadius: 10, height: 130, marginBottom: 10 }}>
                        <h4>Advertisement Slot :{index + 1}</h4>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AdvertisementSection;