import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Subscribe from './Subscribe'
const aboutus = () => {
  return (
    <div className='backgroundbody'>
      <Header />
      <div id="titlebar" className="gradient">


        <nav aria-label="breadcrumb" className="breadcrumb bg-dark">
          <div className="container d-flex justify-content-between align-items-center py-4">
            <h1 className="text-light">ABOUT info.stocometry</h1>

          </div>
        </nav>

      </div>
      <div className="section margin-top-65 padding-bottom-55">

        <div className="container">

          <div className="row">

            <div className="col-xl-12 col-md-12 text-justify">
              <h2 className='mb-5'>Welcome to <b>Info.stocometry.com!</b></h2>
              <p>At <strong>Info.stocometry.com</strong> , we are dedicated to providing you with the most accurate and relevant information tailored to your needs. Our platform is designed to deliver specific results based on your queries, ensuring that you can quickly and efficiently find what you're looking for.</p>

              <h3>
                Our Mission
              </h3>
              <p>
                Our mission is to simplify the search process and enhance your experience by offering advanced search capabilities and filters. We understand that in today’s fast-paced world, time is of the essence. That’s why we’ve developed a system that helps you locate the information you need without any hassle.
              </p>
              <h3>
                What We Offer
              </h3>
              <p>
                Advanced Search Capabilities: Our sophisticated search algorithms are designed to understand and interpret your queries, delivering precise and relevant results every time.
              </p>
              <p>
                Efficient Filters: We provide a range of filters that allow you to narrow down your search and find the most pertinent information quickly.
              </p>
              <h3>
                Our Commitment
              </h3>
              <p>
                We are committed to continuous improvement and innovation, constantly updating our platform to better serve our users. Our team works tirelessly to ensure that Info.stocometry.com remains a reliable and efficient resource for all your information needs.
              </p>

              <p>
                We are located in the heart of Uttarakhand, India, with our headquarters in Delhi, India. If you have any suggestions or feedback on our content, you can reach us at <a href='mailto:contact@stocometry.com' className='font-bold text-black'>contact@stocometry.com</a>.
              </p>
              <p>
                Thank you for choosing <strong>Info.stocometry.com</strong>. We look forward to helping you find the information you need, when you need it.
              </p>
            </div>

          </div>
          <Subscribe />
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default aboutus;