import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  const accessToken = useSelector((state) => state.user);
  if (!accessToken) {
    return;
  } else {
    return children;
  }
};

export default ProtectedRoutes;
