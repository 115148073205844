import React, { useEffect } from 'react';

const Ads1 = () => {
    useEffect(() => {
        // Create the first script element (the inline script)
        const inlineScript = document.createElement('script');
        inlineScript.id = 'mNCC';
        inlineScript.type = 'text/javascript';
        inlineScript.innerHTML = `
            medianet_width = "300";
            medianet_height = "250";
            medianet_crid = "380770422";
            medianet_versionId = "3111299";
        `;

        // Append the inline script to the desired div or body
        document.getElementById('ad-container').appendChild(inlineScript);

        // Create the external script (the one that loads nmedianet.js)
        const externalScript = document.createElement('script');
        externalScript.type = 'text/javascript';
        externalScript.src = 'https://contextual.media.net/nmedianet.js?cid=8CU5UCTM2';

        // Append the external script
        document.getElementById('ad-container').appendChild(externalScript);

        // Cleanup function to remove the scripts when the component unmounts
        return () => {
            document.getElementById('ad-container').removeChild(inlineScript);
            document.getElementById('ad-container').removeChild(externalScript);
        };
    }, []);

    return (
        <div>
            {/* Ad container */}
            <div id="ad-container"></div>
        </div>
    );
};

export default Ads1;
