import React, { useState, useRef, useEffect } from "react";
import "./dropDownSelect.css"; // External CSS for styling

const DropdownSelect = ({ options, placeholder, onSelect, header, objKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option); // Pass the selected option back to parent
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      {/* Header */}
      {header && <div className="custom-dropdown-header-title">{header}</div>}

      <div className="custom-dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? selectedOption?.[objKey] : placeholder}
        <span className={`custom-dropdown-arrow ${isOpen ? "open" : ""}`}>▼</span>
      </div>

      {isOpen && (
        <div className="custom-dropdown-list">
          {/* None Option */}
          <div className="custom-dropdown-item none-option" onClick={() => handleSelect({ [objKey]: "None" })}>
            None
          </div>

          {options.map((option) => (
            <div key={option.value} className="custom-dropdown-item" onClick={() => handleSelect(option)}>
              {option?.[objKey]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
