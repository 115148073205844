import React from 'react';
const ChangeCareer = () => {
  return (
    <div className="row">
      <div className="col-md-12 text-center ">
        <div className="mainheading">
          <h3>Gateway to Discover, Learn, and Explore</h3>
          <span>
            Search Anything, Anywhere - Connecting You to What Matters
          </span>
        </div>
      </div>
    </div>
  );
};
export default ChangeCareer;
