export const INTIAL_ERROR = {
  email: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
};
export const INTIAL_VALUE = {
  email: "",
  password: "",
};
