import React, {useState} from 'react';
import axios from 'axios';
const Subscribe = () => {
  const regex =
  /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
const [emailinputs, setEmail] = useState("");
const [error, setError] = useState("");
const [emailinput,setEmailInput] = useState();
const [emailcheck, setEmailCheck] = useState();
const [subscribeds,setsubscribeds] = useState(false);
  const subscribe = async(e) => {
    e.preventDefault();
    if(emailcheck === true){
      try{
        const data = await axios.get(`/api/memberAdd?email=${emailinput}`)
        setsubscribeds(true)
        console.log("data",data)
      }
      catch(error){
        console.log(error)
      }
    }
   }
   let invalidemail = "Enter the valid Email";
      let valid = "";
      let emailValues = "";
      const checkEmail = (e) => {
        emailValues = e.target.value
        setEmail(e.target.value);
        setEmailInput(e.target.value)
        if (regex.test(e.target.value) === false) {
          setError(invalidemail);
          setEmailCheck(false)
        }
        else {
          setError(valid);
          setEmailCheck(true)
        }
      };
  return (
    <div className="section mb-2">
      <div className="card counter">
        <div className="card-body row">
          <div className="col-xl-8 col-md-12">
            <h2>Get Latest Update!</h2>
            <h4>Subscribe to get latest updates and information.</h4>
          </div>
          <div className="col-xl-4 col-md-12 pt-md-2">
          {subscribeds === true ? (<>
            <p className='text-light text-center font-weight-bold'>You Subscribed Successfully!</p>
          </>):(<>
            <div className="contact-form-action">
              <div id="response"></div>
              <form id="subscribe" method="post">
                <div className="input-group  d-flex flex-nowrap">
                  <div className="input-group-prepend ">
                    <span
                      className="input-group-text border-right-0 bg-light"
                      id="basic-addon1"
                    >
                      <i className="bx bx-envelope"></i>
                    </span>
                  </div>
                  <div className=''>
                  <input
                    className="form-control emails py-3"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    onChange={
        checkEmail}
                    required
                  />
                  
                  </div>
                  <div className="input-group-append ">
                    <button
                      className="btn subscribe text-light"
                      type="submit"
                      name="submit"
                      value="Subscribe"
                      onClick={subscribe}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>)}
            
            <p className="text-danger">{error}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscribe;
