export const getFormattedDate = (date) => {
  const newDate = new Date(date);
  const todayDate = newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`;
  const month = newDate.getMonth() + 1 > 9 ? newDate.getMonth() + 1 : `0${newDate.getMonth() + 1}`;
  const year = newDate.getFullYear();
  return `${year}${month}${todayDate}`;
};

export const protectedRouteCheck = (str = "") => {
  console.log({ str });
  const condition = str.endsWith("/campaign-data") || str.endsWith("/add-campaignId") || str.endsWith("/site-data");

  return condition;
};
