import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { getData } from "../../api/Requests/authentication";
import { useSelector } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
import "./siteData.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { areDates15DaysApart } from "./utility";
import Header from "../Header";

const columns = [
  {
    name: "Ad Units",
    selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.adtag}</div>,
  },
  {
    name: "Channel Name",
    selector: (row) => row.channelName,
  },
  {
    name: "Channel Name 2",
    selector: (row) => row.channelName2,
  },
  {
    name: "Channel Name 3",
    selector: (row) => row.channelName3,
  },
  {
    name: "Date",
    selector: (row) => <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>{row.date}</div>,
  },
  {
    name: "Estimated Revenue",
    selector: (row) => row.estimated_revenue,
  },
  {
    name: "Impressions",
    selector: (row) => row.impressions,
  },
  {
    name: "Searches",
    selector: (row) => row.searches,
  },
  {
    name: "Total Clicks",
    selector: (row) => row.total_clicks,
  },
];

const SiteTable = (props) => {
  const [data, setData] = useState({});
  const { apiToken, accessToken } = useSelector((state) => state.user);
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState("50");
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const getTableData = async () => {
    try {
      setIsLoading(true);
      if (endDate < startDate) {
        toast.error("EndDate cannot be before Start Date", {
          autoClose: 2000,
        });
        setData({});
      } else if (areDates15DaysApart(startDate, endDate)) {
        toast.error("EndDate and Start Date cannot have more than 15 days gap", {
          autoClose: 2000,
        });
        setData({});
      } else {
        const payload = {
          start_date: getFormattedDate(startDate),
          end_date: getFormattedDate(endDate),
          page_size: perPage,
          page_no: page,
          apiToken,
        };
        const res = await getData(payload);
        if (res.status === 200) {
          setData(res.data.message);
        } else {
          setData({});
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [perPage, page, startDate, endDate]);

  console.log(data);
  return (
    <div className="page-container">
      {/* Header */}
      <Header />
      <h1 className="page-title">Campaign Information</h1>

      <div className="date-filter-container">
        <div className="date-picker-wrapper">
          <label htmlFor="start-date">Start Date:</label>
          <DatePicker
            id="start-date"
            selected={startDate}
            onChange={handleStartDate}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select Start Date"
            maxDate={new Date()}
          />
        </div>

        <div className="date-picker-wrapper">
          <label htmlFor="end-date">End Date:</label>
          <DatePicker
            id="end-date"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select End Date"
            maxDate={new Date()}
          />
        </div>
      </div>

      {/* Table Container */}
      <div className="table-site-container">
        <DataTable
          columns={columns}
          data={data.rows}
          pagination
          paginationServer
          progressPending={isLoading}
          paginationTotalRows={data?.pagination?.total_rows}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[40, 50, 60]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<CustomLoader />}
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 350px)"
        />
      </div>
    </div>
  );
};

export default SiteTable;
