import React, { useEffect } from "react";
import Header from "./Header";
import ChangeCareer from "./ChangeCareer";
import SearchForm from "./SearchForm";
import Counters from "./Counters";
import Subscribe from "./Subscribe";
import Footer from "./Footer";
import "./index.css";
import axios from "axios";
const mainpage = () => {
  var base_url = window.location.origin;

  console.log("base_url", base_url);
  var host = window.location.host;
  console.log("host", host);

  return (
    <div>
      <Header />
      <div className="intro-banner custom-gradient">
        <div className="container">
          <ChangeCareer />
          <SearchForm />
          <Counters />
          <Subscribe />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default mainpage;
